/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The state of Virginia is full of hidden dangers that can gradually affect your hearing – from the sound of gunshots while hunting, to the daily wear and tear caused by noisy traffic."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "No matter where you live in Virginia, hear.com can help you find a hearing aid that fits your lifestyle and needs. We carry all the latest hearing aids from the leading manufacturers, so we’re confident we’ll help you find the perfect device."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "its-easy-to-get-hearing-damage-in-virginia",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#its-easy-to-get-hearing-damage-in-virginia",
    "aria-label": "its easy to get hearing damage in virginia permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "It's easy to get hearing damage in Virginia"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’re one of the thousands of Virginia residents who struggle to hear conversations, don’t fret! Hearing damage is common in the state of Virginia, with numerous reported causes. For example, roughly 430,000 Virginia residents hunt at least once a year, logging 10 million hunting days in the process. One 2016 study found that 95% of American hunters had not worn any hearing protection in the previous year."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Without the proper protection, one gunshot close to the ear can result in permanent hearing damage! Of course, hunting isn’t the only way to damage your hearing in Virginia. Sometimes, the damage is done at work—on the farms of the interior, or in the state’s coal mines or aerospace factories."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It could be sustained on a tour of duty with the military, or from a lifetime of driving trucks. Even loud music can be a cause of hearing loss in middle age, and everyone knows that Virginians love their country and folk music! As with any state in The Union, Virginia has its fair share of hearing risks."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, as we age, these hearing risks can cause moderate to severe hearing loss, making it harder for you to comprehend the announcers on TV or your colleagues at work."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-numbers-behind-hearing-loss-in-virginia",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-numbers-behind-hearing-loss-in-virginia",
    "aria-label": "the numbers behind hearing loss in virginia permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The numbers behind hearing loss in Virginia"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to the Center for Disease Control and Prevention’s latest figures, 16% of Americans aged 18 and over have some degree of hearing loss. And they aren’t all seniors. About 65% of Americans with hearing loss are under the age of 65."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "What’s more, a relatively small percentage of those individuals seek help. In fact, just 16% of people under the age of 70 who would benefit from hearing aids choose to wear them. Given the technological advancements in hearing aids over the past few years, that’s a shocking statistic, and one that hear.com aims to change as soon as possible."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearcom-can-help-virginians-solve-their-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearcom-can-help-virginians-solve-their-hearing-loss",
    "aria-label": "how hearcom can help virginians solve their hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How hear.com can help Virginians solve their hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "We are hearing aid specialists, connecting Americans with the latest technology to better their hearing. Thanks to our partnerships with the world’s most advanced hearing aid manufacturers, we carry all the latest, cutting-edge devices at competitive prices."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At the same time, we have created a network of over 80 hearing specialists and audiologists across Virginia, so you no matter where you live in the state, you’ll never be far from one of our trusted Partner Providers. Take the first step and contact our team today."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Simply fill out our online questionnaire and you’ll receive a free, personal phone consultation with one of our hearing aid experts. We’ll schedule an appointment for you with your local Partner Provider for a ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), " and hearing aid fitting. After that, you’ll enjoy a 45 day risk-free trial period to wear, test, and fall in love with your new devices and improved quality of life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-in-touch-with-hearcom-and-improve-your-hearing-today",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-in-touch-with-hearcom-and-improve-your-hearing-today",
    "aria-label": "get in touch with hearcom and improve your hearing today permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get in touch with hear.com and improve your hearing today"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you have moderate to severe hearing loss, the perfect solution could only be a few moments away. Whether your hearing has been damaged by hunting or metalworking, modern hearing aids could be the solution."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Fill out our questionnaire now and schedule a free, personal phone consultation with one of our hearing aid experts. Our experts will work with you to determine which hearing aids match your unique lifestyle and needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In no time, you’ll be enjoying the benefits of today’s finest hearing aids. So, no matter where you are in Virginia, contact us today! We can’t wait to hear from you."), "\n", React.createElement(LandingPageCta, {
    copy: "START NO-RISK TRIAL",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
